.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "IBMPlexSans-Light";
  height: 70%;
  margin: 7vw auto;
 
  position: relative;
  width: 100vw;

  @media screen and (max-width: 1150px) {
    margin: 10rem auto;
  
    
  }
  
  
  .resumebtn {
    margin-top: 3rem;
    flex-grow: 0;
    align-self: flex-start;
    
  }
  
  z-index: 1;

  &__links {
    margin-left: 10vw;
    text-align: center;
    flex-grow: 0;
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1150px) {
      margin-left: 25vw;
    
    }
    
    
  }
  &__link {
    display: flex;
    text-align: center;
    align-items: flex-end;
    padding: 2rem 0;
    a, p {
      font-family: "IBMPlexSans-Light";
      letter-spacing: 2px;
      font-size: 2rem;
      color: white;
      margin-left: 1.2rem;
      text-decoration: none;
      border: 0;
      position: relative;
      
      @media screen {
        
      }
      
  
      &::before,
      &::after {
        box-sizing: inherit;
        content: "";
        position: absolute;
        top: .2rem;
        left: 0;
        height: 100%;
        width: 100%;
        transform-origin: center;
        transition: transform 0.5s;
      }
  
      &::before {
        
        border-bottom: 2px solid #005C46;
        transform: scale3d(0, 1, 1);
      }
      &::after {
       
        transform: scale3d(1, 0, 1);
      }
      &:hover::before,
      .center:hover::after {
        transform: scale3d(1, 1, 1);
        transition: transform 0.5s;
      }
  
      &:hover {
          color: #BAF6F9;
          transition: color 0.5s;
      }
    }
    
    .linkedin {
        position: relative;
        bottom: 3px;
    }

    #linkedin {
        position: relative;
        left: .5rem;
    }

  }
  span {
    font-family: "IBMPlexSans-Light";
    position: relative;
    opacity: 0;
    left: 1rem;
    transform: translateY(20px);
    letter-spacing: 2px;
    font-size: 1.5rem;
    color: teal;
  }

  
}
