.mobile-navbar {
  color: white;
  display: flex;

  &__container {
    width: 100%;
    background-color: var(--bg);
    border-bottom: 1px solid white;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3% 0.4rem 3%;
    position: fixed;
    background-clip: padding-box;

    @media screen and (max-width: 900px) {
      padding: 1rem 1% 0.4rem 1%;
      top: 0rem;
      
    }
  }

  &__title {
    display: flex;
    align-items: last baseline;

    svg {
      height: 60%;
    }

    a {
      position: relative;
      top: -0.2rem;
      text-decoration: none;
      color: white;
      font-family: "IBMPlexMono";
      font-size: 2rem;
      letter-spacing: +2px;
      padding: 0 1rem;
    }
  }

  &__menu {
    position: relative;
    top: .5rem;
  }
  &__links {
    display: flex;
    position: fixed;
    right: 0;
    top: 5.8rem;
    width: 18rem;
    padding-right: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -2rem;
    margin-bottom: 0.5rem;
    background-color: #151515f1;
    transform: translateX(20rem);
    z-index: 1;
    transition: 1s all;
    

    a {
      position: relative;
      top: 0.5rem;
      font-family: "IBMPlexMono";
      font-size: 1.8rem;
      text-decoration: none;
      color: white;
      padding: 0 1.8rem;
      margin-bottom: 2rem;
      background-color: transparent;
      @media screen and (max-width: 1200px) {
        padding: 0 1rem;
        
      }

      &:hover {
        transition: background-color 0.5s;
        background-color: #1a1a1a;
      }
    }

    a::before {
      content: "";
      position: absolute;
      align-self: center;
      top: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: calc(100% - 2rem);
      height: 0.5rem;
      background-color: red;

      transform-origin: center;
      transform: scaleX(0);
    }
  }

  &__container--block {
    visibility: hidden;
    width: 100%;
    background-color: var(--bg);
    border-bottom: 1px solid white;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 3% 0rem 3%;
    position: relative;
    background-clip: padding-box;
  }

  &__links.active {
    display: flex;
    transform: translateX(0);
    transition: 1s all;
  }
}
