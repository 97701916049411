.artimage {
  color: white;
  width: 100%;
  overflow-x: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.imagecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  border-radius: 2.6rem;
  width: 100%;

  &:hover {
    img {
        transition: 1s all;
        border: 1px solid teal;
      }

    .imagecard__title {
        opacity: 1;
        transition: opacity 1s;
    }
  }
  

 
  &__title {
    font-family: "IBMPlexSans-Thin";
    
    width: 100%;
    text-align: left;
    font-size: 2rem;
    color: white;
    // border: 1px solid rgb(45, 45, 45);
    border-radius: 2.6rem;
    padding: .6rem 2rem;
    opacity: 0;
    transition: opacity .5s;

    
  }
   img {
    padding: 0.8rem;
    border-radius: 2.6rem;
    width: 100%;
    height: 30rem;
    border: 1px solid transparent;
    object-fit: cover;
    object-position: 50% 83%;
    filter: brightness(85%);
    cursor: pointer;
    transition: 1s all;
}

 

   

   

  &__buttons {
    display: flex;

    label {
      text-align: center;
      font-family: "IBMPlexSans";
      color: white;
      font-size: 1.6rem;
      background-color: var(--bg);
      width: 12rem;
      padding: 1rem 1rem;
      margin: 1rem 2rem;
      margin-bottom: 1rem;
      border-radius: 3rem;
      cursor: pointer;
      display: block;

      background-color: #12172a;
      border: 1px solid #1a2038;

      &:hover {
        background-color: #353a4c;
      }
    }

    button {
      font-family: "IBMPlexSans";
      color: white;
      font-size: 1.6rem;
      background-color: var(--bg);
      width: 12rem;
      padding: 1rem 1rem;
      margin: 1rem 2rem;
      margin-bottom: 1rem;
      border-radius: 3rem;
      cursor: pointer;

      &:last-child {
        background-color: #1c274c;
        border: 1px solid rgb(31, 37, 62);

        &:hover {
          background-color: #353a4c;
        }
      }
    }
  }

  &__readmore {
    opacity: 1;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;

    hr {
      border: 1px solid rgba(128, 128, 128, 0.342);
      width: 70%;
      margin: 2rem auto;
    }
    p {
      width: 100%;
      font-family: "IBMPlexSans";
      font-size: 1.6rem;
      color: white;
      margin-bottom: 2rem;
    }
  }
}


#collapsible {
  display: none;
}
