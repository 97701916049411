.projects {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20rem;

  @media screen and (max-width: 700px) {
    margin-top: 12rem;
  }
    
  
}



.projectcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 2.6rem;
  width: 38%;
  padding: 0.8rem;
  margin-bottom: 15rem;

  transition: 1s all;

  @media (min-width: 1600px) {
    width: 30%;
  }
  @media (max-width: 1300px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 90%;
    margin-bottom: 7rem;
  }
    
  
  

  &:hover {
    transition: 1s all;
    border: 1px solid teal;
  }
  &__title {
    font-family: "IBMPlexMono-Bold";
    font-size: 2.4rem;
    color: white;
  }

  &__subtitle {
    font-family: "IBMPlexSans-Light";
    color: white;
    font-weight: lighter;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  img {
    margin-bottom: 1rem;
    border-radius: 2.6rem;
    width: 100%;
    height: 30rem;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__buttons {
    display: flex;

  

    label {
      text-align: center;
      font-family: "IBMPlexSans";
      color: white;
      font-size: 1.6rem;
      background-color: var(--bg);
      width: 12rem;
      padding: 1rem 1rem;
      margin: 1rem 2rem;
      margin-bottom: 1rem;
      border-radius: 3rem;
      cursor: pointer;
      display: block;

      background-color: #12172a;
      border: 1px solid #1a2038;

      &:hover {
        background-color: #353a4c;
      }
    }

    button {
      font-family: "IBMPlexSans";
      color: white;
      font-size: 1.6rem;
      background-color: var(--bg);
      width: 12rem;
      padding: 1rem 1rem;
      margin: 1rem 2rem;
      margin-bottom: 1rem;
      border-radius: 3rem;
      cursor: pointer;

      &:last-child {
        background-color: #1c274c;
        border: 1px solid rgb(31, 37, 62);

        &:hover {
          background-color: #353a4c;
        }
      }
    }
  }

  &__visitsite {
    text-align: center;
    text-decoration: none;
    font-family: "IBMPlexSans";
    color: white;
    font-size: 1.6rem;
    background-color: var(--bg);
    width: 12rem;
    padding: 1rem 1rem;
    margin: 1rem 2rem;
    margin-bottom: 1rem;
    border-radius: 3rem;
    cursor: pointer;

    &:last-child {
      background-color: #1c274c;
      border: 1px solid rgb(31, 37, 62);

      &:hover {
        background-color: #353a4c;
      }
    }

  }

  &__readmore {
    
    opacity: 1;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;

    hr {
      border: 1px solid rgba(128, 128, 128, 0.342);
      width: 70%;
      margin: 2rem auto;
    }
    p {
      width: 100%;
      font-family: "IBMPlexSans";
      font-size: 1.6rem;
      color: white;
      margin-bottom: 2rem;
    }

    
  }
}

.projectcard__readmore.checked {
    max-height: 33rem;
    transition: max-height 0.5s ease-in-out;
}

#collapsible {
    display: none;
  }