.home-container {
  z-index: 1;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home {
  --h1font: "IBMPlexMono-Bold";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: var(--h1font);
  height: 100%;
  margin-top: 30vh;
  z-index: 1;

  &__circleSVG {
    text-align: center;
    position: absolute;
    margin: 0 auto;
    width: 40%;
    z-index: -1;
    
    @media screen and (max-width: 900px) {
      width: 60%;
      
    
      
    }

    #circlepath {
      transform-origin: center;
    }
  }

  &__titlebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90%;
    height: 90%;
    // background-image: url("../assets/svg/homecircle.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: contain;
  }

  &__title {
    text-align: center;
    font-family: "IBMPlexMono-Bold";
    font-size: 4.8rem;
    margin-top: 3%;
    padding-left: 6px;
    letter-spacing: 3px;
    @media screen and (max-width: 900px) {
      font-size: 3rem;
      margin-top: 2rem;
      letter-spacing: 2px;
    
      
    }

    span {
      font-family: "IBMPlexMono-Thin";
      font-weight: lighter;
    }
  }

  &__subtitle {
    text-align: center;
    font-family: "IBMPlexSans-Thin";
    margin: 2rem 0;
    font-size: 3.6rem;
    letter-spacing: 3px;
    font-weight: lighter;

    @media screen and (max-width: 900px) {
      font-size: 1.8rem;
      margin-top: 1.5rem;
    
      
    }
  }

  &__resume {
    text-align: center;
    font-family: "IBMPlexSans";
    font-size: 3rem;
    margin-top: 2rem;
    padding: 1.5rem 5rem;
    padding-left: 4.1rem;
    color: white;
    border: 1px solid #3b3b3b;
    border-radius: 5rem;
    background-color: transparent;
    font-weight: lighter;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 1s;

    @media screen and (max-width: 900px) {
      margin-top: 0;
    }

    &:hover {
      -webkit-box-shadow: -1px 0px 44px -14px rgba(255, 255, 255, 1);
      -moz-box-shadow: -1px 0px 44px -14px rgba(255, 255, 255, 1);
      box-shadow: -1px 0px 44px -14px rgba(255, 255, 255, 1);
    }

    a {
      color: white;
      text-decoration: none;
    }

    &::after {
      content: "";
      position: relative;
      display: inline-block;
      top: 0.2rem;
      left: 0.8rem;
      width: 27px;
      height: 27px;
      background-image: url("../assets/svg/download.svg");
    }
  }
}
