*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html {

  background-color: var(--bg);
  overflow-x: hidden;
}

body {
  --bg: #070707;
  
  background-color: var(--bg);
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "IBMPlexMono";
  src: url("./assets/fonts/IBMPlexMono-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexMono-Thin";
  src: url("./assets/fonts/IBMPlexMono-Thin.ttf");
}

@font-face {
  font-family: "IBMPlexMono-Bold";
  src: url("./assets/fonts/IBMPlexMono-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./assets/fonts/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Thin";
  src: url("./assets/fonts/IBMPlexSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "IBMPlexSans-Light";
  src: url("./assets/fonts/IBMPlexSans-Light.ttf") format("truetype");
}