.art {
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 3%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5% 5%;
  grid-auto-flow: rows;
  width: 55vw;
  
  @media screen and (max-width: 1600px) {
    margin-top: 5rem;
    width: 70vw;
    gap: 1% 5%;
  }
  @media screen and (max-width: 1300px) {
    margin-top: 5rem;
    width: 90vw;
  }
  @media screen and (max-width: 1180px) {
    margin-top: 5rem;
    width: 90vw;
    gap: 1% 1%;
  }
  @media screen and (max-width: 700px) {
    margin-top: 5rem;
    width: 90vw;
    grid-template-columns: repeat(2, 1fr);
    gap: 1% 1%;
  }
    
  
}
