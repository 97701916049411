.music {

    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;

 
}

.player {
    
    width: 75%;
    border: 0;
    border-radius: 1rem;
    
}